import axios from 'axios'

const axiosHttps = axios.create({
    //Desarrollo
    //baseURL: 'http://66.240.236.10:8010/api/'
    //Produccion
    baseURL: 'https://erp.gpsgoldcar.com/api/'
  });

  const axiosDocument = axios.create({
    baseURL: 'https://www.tus-ventas-facil.com/api/v1/'
  });

const axiosDownload ='';
  axiosHttps.interceptors.request.use(
    async (config) =>{
        const token = await localStorage.getItem('token')
        if( token ){
            config.headers['Authorization'] = "Token " + token;
        }
        return config;
    }
)

  export {
    axiosHttps, axiosDocument, axiosDownload
  };